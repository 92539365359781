<template>
	<keep-alive>
		<component
			:is="selectedComponent"
			:selectedComponent="selectedComponent"
			:template="template"
			:message="message"
			:quizName="quizName"
			:responses="responses"
			:question="question"
			:isTriggered="isTriggered"
			:type="type"
			@selectComponent="selectComponent"
			@selectTemplate="selectTemplate"
			@setValue="setValue"
			@createQuestion="createQuestion"
			@setMessage="setMessage"
			@saveQuestion="saveQuestion"
			@addNewResponses="addNewResponses"
		>
		</component>
	</keep-alive>
</template>
<script>
import { post } from "@/util/requests/post";
import { mapGetters } from "vuex";
import QuizEditorAddNewQuestionStep1 from "./QuizEditorAddNewQuestionStep1.vue";
import QuizEditorAddNewQuestionStep2 from "./QuizEditorAddNewQuestionStep2.vue";
import { v4 as uuidv4 } from "uuid";
import { get } from "@/util/requests/get";
import { put } from "@/util/requests/put";

export default {
	name: "QuizEditorAddNewQuestion",
	components: {
		QuizEditorAddNewQuestionStep1,
		QuizEditorAddNewQuestionStep2
	},
	computed: {
		...mapGetters(["organization"]),
		questionId() {
			if (!!this.$route.query.edit === false) return;
			return this.$route.params.id;
		}
	},
	data() {
		return {
			selectedComponent: "QuizEditorAddNewQuestionStep1",
			type: "",
			template: null,
			message: "",
			wantToAddToBankQuestion: false,
			quizName: "Quiz name",
			questionData: {},
			responsesData: [],
			question: "",
			isTriggered: false,
			triggerQuestion: "",
			triggerResponse: "",
			responses: []
		};
	},
	methods: {
		addNewResponses(
			id = uuidv4(),
			responseText = "",
			score = 0,
			isCorrect = null,
			isNew = true
		) {
			this.responses.push({
				id: id,
				responseText: responseText,
				score: score,
				isCorrect: isCorrect,
				isNew: isNew
			});
		},
		async getQuestionData() {
			try {
				this.$store.dispatch("setIsLoading", true);
				const {
					data: { data }
				} = await get(`/question/${this.questionId}`);
				this.question = data.content;
				this.type = data.type;
				this.questionData = data;
				this.responsesData = data.responses;
				data.responses.forEach(el => {
					this.addNewResponses(
						el.id,
						el.content,
						0,
						el.risk_score === "1.00",
						false
					);
				});
			} catch (error) {
				console.error(error);
			}
			this.$store.dispatch("setIsLoading", false);
		},
		setValue({ key, value }) {
			this[key] = value;
		},
		selectComponent(cmp) {
			this.selectedComponent = cmp;
		},
		selectTemplate(template) {
			this.template = template;
		},
		setMessage(val) {
			this.message = val;
		},
		async validate() {
			return !!this.question && !!this.type;
		},

		async createQuestion() {
			if ((await this.validate()) === false) {
				this.setMessage("Check fields");
				return;
			}
			try {
				this.setMessage("");
				await this.$store.dispatch("setIsLoading", true);
				const question = await post("/question", {
					label: "question",
					content: this.question,
					help_html: "<h2>help</h2>",
					help_text: "help",
					type: this.type,
					required: false,
					sort_order: 0,
					custom: false,
					organization_id: this.organization.id,
					category_id: 1
				});
				const {
					data: { data }
				} = question;

				if (this.isTriggered === true) {
					const { data: parentData } = await post(
						"/question_condition_parent",
						{
							question_id: this.triggerQuestion,
							response_id: this.triggerResponse,
							operator: "",
							response_text: "response"
						}
					);
					await post("/question_condition_child", {
						question_id: data.id,
						question_condition_parent_id: parentData.data.id,
						custom: Number(false),
						organization_id: 1,
						category_id: 1
					});
				} else {
					await post("/quiz_question", {
						quiz_id: Number(this.$route.params.id),
						question_id: data.id,
						custom: false,
						organization_id: this.organization.id,
						category_id: "1"
					});
				}
				if (this.wantToAddToBankQuestion) {
					await post("/org_bank_question", {
						organization_id: this.organization.id,
						category_id: 1,
						question_id: data.id
					});
				}
				Promise.all(
					this.responses.map(async el => {
						await post("/response", {
							question_id: data.id,
							content: el.responseText,
							help_text: "help",
							sort_order: 0,
							explanation: "",
							risk_score: Number(el.isCorrect),
							custom: Number(false),
							organization_id: this.organization.id,
							category_id: 1
						});
					})
				);
				this.$router.push({
					name: "QuizEditor",
					id: this.$route.params.id
				});
			} catch (error) {
				this.$emit("setMessage", error.response.data.message);
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		async saveQuestion() {
			const {
				data: { data }
			} = await put("/question", this.questionId, {
				label: this.questionData.label,
				content: this.question,
				help_html: this.questionData.help_html,
				help_text: this.questionData.help_text,
				type: this.type,
				required: this.questionData.required,
				sort_order: this.questionData.sort_order,
				custom: this.questionData.custom,
				organization_id: this.questionData.organization_id,
				category_id: this.questionData.category_id
			});
			Promise.all(
				this.responses.map(async el => {
					if (el.isNew === true) {
						await post("/response", {
							question_id: data.id,
							content: el.responseText,
							help_text: "help",
							sort_order: 0,
							explanation: "",
							risk_score: Number(el.isCorrect),
							custom: Number(false),
							organization_id: this.organization.id,
							category_id: 1
						});
					} else {
						const originReponse = this.responsesData.find(
							originEl => originEl.id === el.id
						);
						await put("/response", el.id, {
							question_id: data.id,
							content: el.responseText,
							help_text: originReponse.help_text,
							sort_order: originReponse.sort_order,
							explanation: originReponse.explanation,
							risk_score: Number(el.isCorrect),
							custom: originReponse.custom,
							organization_id: originReponse.organization_id,
							category_id: originReponse.category_id
						});
					}
				})
			);
			const additionalParameter = {
				id:
					this.$route.query.from === "QuizEditor"
						? Number(this.$route.query.quiz)
						: null
			};
			console.log({
				name: this.$route.query.from,
				...additionalParameter
			});

			this.$router.push({
				name: this.$route.query.from,
				params: {
					...additionalParameter
				}
			});
		}
	},
	async created() {
		if (this.$route.query.edit === "true") {
			this.selectedComponent = "QuizEditorAddNewQuestionStep2";
			await this.getQuestionData();
			return;
		}
		this.addNewResponses();
	}
};
</script>

<style></style>
