var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row header align-end"},[_c('div',{staticClass:"col-6"},[_c('backTo',{attrs:{"link":`/admin/quiz-editor/${_vm.$route.params.id}`},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v("Back to Quiz")]},proxy:true}])}),_c('h3',{staticClass:"mt-5"},[_vm._v("Add New Question")])],1)]),_c('div',{staticClass:"row new-course"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"brand-form"},[_c('v-form',{ref:"form"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("How would you like to start?")]),_c('div',{staticClass:"new-course-card-container"},[_c('figure',{staticClass:"admin-default-big-card",class:{
							'admin-default-big-card--selected':
								_vm.template === 'basic'
						},on:{"click":function($event){return _vm.$emit('selectTemplate', 'basic')}}},[_c('label',{staticClass:"btn btn-light shadow-none",attrs:{"for":"new-course-template-file"}},[_vm._v("Basic Template ")])]),_c('figure',{staticClass:"admin-default-big-card",class:{
							'admin-default-big-card--selected':
								_vm.template === 'file'
						},on:{"click":function($event){return _vm.$emit('selectTemplate', 'file')}}},[(_vm.template === 'file')?_c('v-file-input',{staticClass:"template-input",attrs:{"id":"new-quiz-template-file","chips":"","truncate-length":"50","accept":".csv","loading":_vm.isLoading},model:{value:(_vm.templateFile),callback:function ($$v) {_vm.templateFile=$$v},expression:"templateFile"}}):_vm._e(),_c('label',{staticClass:"btn btn-light shadow-none",attrs:{"for":"new-course-template-file"}},[_vm._v("Add from Question Bank ")])],1)])])])],1)])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row justify-content-center align-items-center"},[_c('div',{staticClass:"col"},[(_vm.message)?_c('FormMessage',[_vm._v(_vm._s(_vm.message))]):_vm._e()],1),_c('div',{staticClass:"col text-right"},[_c('v-btn',{staticClass:"admin-primary-button primary-contrast-background",attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading},on:{"click":_vm.goNext}},[_vm._v("Next")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }