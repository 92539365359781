<template>
	<div class="container">
		<div class="row header align-end">
			<div class="col-6">
				<backTo
					:link="`/admin/quiz-editor/${$route.params.id}`"
					><template #text>Back to Quiz</template></backTo
				>
				<h3 class="mt-5">Add New Question</h3>
			</div>
		</div>

		<div class="row new-course">
			<div class="col-12">

				<div class="brand-form">
					<v-form ref="form">
						<div class="form-group">
							<label>How would you like to start?</label>

							<div class="new-course-card-container">
								<figure
							class="admin-default-big-card"
							:class="{
								'admin-default-big-card--selected':
									template === 'basic'
							}"
							@click="$emit('selectTemplate', 'basic')"
						>


							<label
										for="new-course-template-file"
										class="btn btn-light shadow-none"
										>Basic Template
									</label>
						</figure>

						<figure
							class="admin-default-big-card"
							:class="{
								'admin-default-big-card--selected':
									template === 'file'
							}"
							@click="$emit('selectTemplate', 'file')"
						>
							<v-file-input
								v-if="template === 'file'"
								id="new-quiz-template-file"
								v-model="templateFile"
								chips
								truncate-length="50"
								accept=".csv"
								:loading="isLoading"
								class="template-input"
							></v-file-input>

							<label
										for="new-course-template-file"
										class="btn btn-light shadow-none"
										>Add from Question Bank
									</label>
						</figure>
							</div>
						</div>
					</v-form>
				</div>
			</div>
		</div>

		<div class="col-12">
				<div class="row justify-content-center align-items-center">
					<div class="col">
						<FormMessage v-if="message">{{ message }}</FormMessage>
					</div>
					<div class="col text-right">


						<v-btn
					class="admin-primary-button primary-contrast-background"
					:loading="isLoading"
					:disabled="isLoading"
					@click="goNext"
					>Next</v-btn
				>
					</div>
				</div>
			</div>

	</div>
	<!-- <v-container class="main-container">
		<v-row>
			<v-col cols="8" class="pa-0">
				<backTo
					elClass="mb-10 d-block"
					:link="`/admin/quiz-editor/${$route.params.id}`"
					><template #text>Back to quizes</template></backTo
				>
				<admin-default-subheader class="mb-4">
					{{ newQuizName }}
				</admin-default-subheader>
				<admin-default-header
					>Add New Question</admin-default-header
				> </v-col
			><v-col cols="4" class="d-flex justify-end">
				<admin-default-subheader> 1 of 2</admin-default-subheader>
			</v-col>
		</v-row>
		<v-row class="mt-16 mb-4">
			<admin-default-subheader>
				How would you like to start?
			</admin-default-subheader>
		</v-row>
		<v-form ref="form">
			<v-row>
				<v-container class="new-quiz-card-container mb-16 mt-4">
					<figure
						class="admin-default-big-card"
						:class="{
							'admin-default-big-card--selected':
								template === 'basic'
						}"
						@click="$emit('selectTemplate', 'basic')"
					>
						<v-btn
							class="admin-primary-button secondary-contrast-background"
							>Basic Template</v-btn
						>
					</figure>

					<figure
						class="admin-default-big-card"
						:class="{
							'admin-default-big-card--selected':
								template === 'file'
						}"
						@click="$emit('selectTemplate', 'file')"
					>
						<v-file-input
							v-if="template === 'file'"
							id="new-quiz-template-file"
							v-model="templateFile"
							chips
							truncate-length="50"
							accept=".csv"
							:loading="isLoading"
							class="template-input"
						></v-file-input>
						<label
							for="new-quiz-template-file"
							class="admin-primary-button secondary-contrast-background"
							>Add from Question Bank
						</label>
					</figure>
				</v-container>
			</v-row>
		</v-form>

		<v-row>
			<v-col>
				<FormMessage v-if="message">{{ message }}</FormMessage>
			</v-col>
		</v-row>
		<v-row class="mt-16">
			<v-col class="d-flex justify-end">
				<v-btn
					class="admin-primary-button primary-contrast-background"
					:loading="isLoading"
					:disabled="isLoading"
					@click="goNext"
					>Next</v-btn
				>
			</v-col>
		</v-row>
	</v-container> -->
</template>

<script>
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import FormMessage from "@/components/FormMessage.vue";
import BackTo from "@/components/BackTo.vue";
import { mapGetters } from "vuex";

export default {
	name: "QuizEditorAddNewQuestionStep1",
	props: ["template", "newQuizName", "message", "templateFile"],
	components: {
		AdminDefaultSubheader,
		AdminDefaultHeader,
		FormMessage,
		BackTo
	},
	computed: {
		...mapGetters(["isLoading"])
	},
	data() {
		return {};
	},
	methods: {
		async validate() {
			this.$emit("setMessage", "");
			if (this.template === "file") {
				this.$emit(
					"setMessage",
					"Content from file is not available yet, please select basic template"
				);
				return false;
			}
			return await this.$refs.form.validate();
		},
		async goNext() {
			this.$emit("setMessage", "");
			if ((await this.validate()) === false) {
				return;
			}
			this.$emit("selectComponent", "QuizEditorAddNewQuestionStep2");
		}
	}
};
</script>

<style></style>
